import * as THREE from 'three'
import React, { Suspense, useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { EffectComposer, N8AO } from '@react-three/postprocessing'
import { ContactShadows, Float, useGLTF } from '@react-three/drei'
import { a, useTransition, useSpring } from '@react-spring/three'
import { FontLoader, TextGeometry } from 'three-stdlib'
import create from 'zustand'

const useStore = create((set) => {
  new FontLoader().load('/Roboto Condensed_Regular.json', (font) => {
    const config = { font, size: 5, height: 2, curveSegments: 4, evelEnabled: false }
    set({
      items: [
        { position: [-3, 0, 2], r: 0.2, geometry: new THREE.TetrahedronGeometry(2) },
        { position: [3, -0.75, 4], r: 0.3, geometry: new THREE.CylinderGeometry(0.8, 0.8, 2, 32) },
        { position: [-2, 2.5, -2], r: 0.6, geometry: new THREE.IcosahedronGeometry(2) },
        { position: [-1, -0.75, 3], r: 0.35, geometry: new THREE.TorusGeometry(1.1, 0.35, 16, 32) },
        { position: [3, 0.5, -2], r: 0.8, geometry: new THREE.OctahedronGeometry(2) },
        { position: [-5.2, 0, -10], r: 0, geometry: new TextGeometry('SPANHOVE', config) },
        { position: [-2.9, -2, -10], r: 0, geometry: new TextGeometry('MEDIA', config) }
      ]
    })
  })
  return { items: [], material: new THREE.MeshStandardMaterial({ color: new THREE.Color('#dedede') }) }
})

function Geometry({ r, position, ...props }) {
  const ref = useRef()
  useFrame((state) => {
    ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z += 0.004 * r
    ref.current.position.y = position[1] + Math[r > 0.5 ? 'cos' : 'sin'](state.clock.getElapsedTime() * r) * r
  })
  return (
    <group position={position} ref={ref}>
      <a.mesh {...props} />
    </group>
  )
}

function Geometries() {
  const { items, material } = useStore()

  const transition = useTransition(items, {
    from: { scale: [0, 0, 0], rotation: [0, 0, 0] },
    enter: ({ r }) => ({ scale: [1, 1, 1], rotation: [r * 3, r * 3, r * 3] }),
    leave: { scale: [0.1, 0.1, 0.1], rotation: [0, 0, 0] },
    config: { mass: 5, tension: 1000, friction: 100 },
    trail: 100
  })

  return transition((props, { position: [x, y, z], r, geometry }) => (
    <Geometry position={[x * 3, y * 3, z]} material={material} geometry={geometry} r={r} {...props} />
  ))
}

function Rig() {
  const { camera, mouse } = useThree()
  const vec = new THREE.Vector3()
  return useFrame(() => camera.position.lerp(vec.set(mouse.x * 2, mouse.y * 1, camera.position.z), 0.02))
}

function Model({ name, floatIntensity = 10, ...props }) {
  const { nodes } = useGLTF('/shapes.glb')

  return (
    <Float {...props} rotationIntensity={2} floatIntensity={floatIntensity} speed={1}>
      <mesh geometry={nodes[name].geometry} material={nodes[name].material} rotation={[Math.PI / 2, 0, 0]} />
    </Float>
  )
}

export default function App() {
  const { color } = useSpring({ color: 0, from: { color: 1 }, config: { friction: 50 }, loop: true })
  const width = window.innerWidth
  const scale = width > 1000 ? 1 : width > 700 ? 0.8 : 0.4

  return (
    <Canvas camera={{ position: [0, 0, 15], near: 5, far: 40 }}>
      <color attach="background" args={['#fafafa']} />
      <a.fog
        attach="fog"
        args={['#79adbd', 10, 40]}
        color={color.to([0, 0.1, 0.2, 0.3, 0.5, 0.6, 0.7, 0.5, 0.3], ['#f0f0f0', '#79adbd', '#f0f0f0', '#79adbd', '#f0f0f0'])}
      />
      <ambientLight intensity={0.8} />
      <directionalLight castShadow position={[2.5, 12, 12]} intensity={4} />
      <pointLight position={[20, 20, 20]} />
      <pointLight position={[-20, -20, -20]} intensity={5} />
      <Suspense fallback={null}>
        <group scale={[scale, scale, scale]}>
          <Model scale={1.5} position={[-2, 3, 0]} rotation={[0, Math.PI, 0]} name="VR_Headset" />
          <Model scale={0.2} position={[5, -2, 0]} rotation={[0, 0, 0]} name="Notebook" />

          <Geometries />
        </group>
        <ContactShadows position={[0, -7, 0]} opacity={0.75} scale={60} blur={1} far={9} />
        <EffectComposer disableNormalPass>
          <N8AO aoRadius={3} distanceFalloff={3} intensity={2} />
        </EffectComposer>
      </Suspense>
      <Rig />
    </Canvas>
  )
}
